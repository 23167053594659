




















































import { MEDIA_NAMESPACE } from "@/shared/media/store";
import { ImageMedia } from "@/shared/media/types";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";

@Component({})
export default class ProfileAvatars extends Vue {
  categories = [
    { title: "All" },
    { title: "Food" },
    { title: "Gaming" },
    { title: "Monsters" },
    { title: "Music" },
    { title: "Nature" },
    { title: "Origami" },
    { title: "Retro" },
    { title: "Robots" },
    { title: "Sports" },
  ];
  selectedCategory = "All";
  selectedAvatar = "";

  @Getter("avatars", { namespace: MEDIA_NAMESPACE })
  readonly mediaAvatars!: ImageMedia[];

  @Action("fetchAvatars", { namespace: MEDIA_NAMESPACE })
  fetchAvatars!: () => Promise<void>;

  @Prop() readonly avatar!: string;

  get avatars() {
    if (this.selectedCategory === "All") {
      return this.mediaAvatars;
    }
    return this.mediaAvatars.filter(
      (avatar: ImageMedia) =>
        avatar.tags.findIndex(
          (tag: string) => tag === this.selectedCategory
        ) !== -1
    );
  }

  created(): void {
    this.selectedAvatar = this.avatar;
    this.fetchAvatars();
  }

  selectCategory(category: string): void {
    this.selectedCategory = category;
  }

  selectAvatar(avatar: string): void {
    if (this.selectedAvatar !== avatar) this.selectedAvatar = avatar;
    else
      this.selectedAvatar =
        "https://cdn.spect8.io/chat/avatars/baseline_account_circle_white_48dp_2x.png";
    this.$emit("selectedAvatar", this.selectedAvatar);
  }
}
