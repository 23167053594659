
























import { MEDIA_NAMESPACE } from "@/shared/media/store";
import { ImageMedia } from "@/shared/media/types";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";

@Component({})
export default class ProfileBadges extends Vue {
  selectedBadge = "";

  @Getter("badges", { namespace: MEDIA_NAMESPACE })
  readonly badges!: ImageMedia[];

  @Action("fetchBadges", { namespace: MEDIA_NAMESPACE })
  fetchBadges!: (dark: boolean) => Promise<void>;

  @Prop() readonly badge!: string;

  created(): void {
    this.selectedBadge = this.badge;
    this.fetchBadges(this.$vuetify.theme.dark);
  }

  selectBadge(badge: string): void {
    if (this.selectedBadge !== badge) this.selectedBadge = badge;
    else this.selectedBadge = "";
    this.$emit("selectedBadge", this.selectedBadge);
  }
}
