import { AppRootState } from "@/shared/@types/app";
import { Module } from "vuex";
import { UiEventState } from "../types";
import { actions } from "./actions";
import { getters } from "./getters";
import { mutations } from "./mutations";

export const state: UiEventState = {};

export const UI_EVENT_NAMESPACE = "uiEvent";

const namespaced = true;

export const uiEvent: Module<UiEventState, AppRootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
