




import ProfileDialog from "@/panel/profile/ProfileDialog.vue";
import ProfileEditDialog from "@/panel/profile/ProfileEditDialog.vue";
import { VueConstructor } from "vue";
import { Vue, Component, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { UI_EVENT_NAMESPACE } from "../ui-event/store";
import { UiEvent, UiEventACTION, UiEventCategory } from "../ui-event/types";

@Component({})
export default class DialogRoot extends Vue {
  component: VueConstructor | null = null;

  @Getter("uiEvent", { namespace: UI_EVENT_NAMESPACE })
  readonly uiEvent!: UiEvent;
  @Watch("uiEvent")
  onUiEventChanged(uiEvent: UiEvent): void {
    if (uiEvent && uiEvent.category === UiEventCategory.PROFILE) {
      this.component =
        uiEvent.action === UiEventACTION.OPEN ? ProfileDialog : null;
    } else if (uiEvent && uiEvent.category === UiEventCategory.PROFILE_EDIT) {
      this.component =
        uiEvent.action === UiEventACTION.OPEN ? ProfileEditDialog : null;
    }
  }

  handleClose(): void {
    this.component = null;
  }
}
