import { Module } from "vuex";
import { mutations } from "./mutations";
import { getters } from "./getters";
import { actions } from "./actions";

import { PanelRootState } from "@/panel/store/PanelRootState";
import { AdState } from "../types";

const namespaced = true;

export const state: AdState = {};

export const ad: Module<AdState, PanelRootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
