




























import { CONFIG_NAMESPACE } from "@/shared/config/store";
import { isGema } from "@/shared/utils";
import { Component, Prop, Ref, Vue, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { NavigationId, NavigationItem } from "../types";
import { NAVIGATION_NAMESPACE } from "../store";

@Component({})
export default class Navigation extends Vue {
  @Prop()
  navbarItems!: NavigationItem[];

  @Getter(`${CONFIG_NAMESPACE}/tenantName`) tenantName!: string;

  @Ref("navigationContainer") navigationContainer!: HTMLDivElement | null;

  // TODO: Convert to feature
  get enableNavText() {
    return isGema(this.tenantName);
  }

  // TODO: Make this dynamical / customisable in the backoffice.
  // Consider: i18n.
  tabName(name: NavigationId): string {
    switch (name) {
      case NavigationId.RESOURCES:
        return "Dokumente";
      case NavigationId.QA:
        return "Antrag zur Geschäftsordnung";
      case NavigationId.POLLS:
        return this.isAttendingInPerson
          ? "Präsenz-Voting"
          : "Online-Live-Voting";
      default:
        return "";
    }
  }

  @Getter(`${NAVIGATION_NAMESPACE}/attendingInPerson`)
  readonly isAttendingInPerson!: boolean;

  // Nav Text throws out slider width calculation
  setSliderWidth() {
    if (!this.enableNavText) return;

    const navItem = this.navigationContainer?.querySelector(".v-tab");
    const navSlider = this.navigationContainer?.querySelector(
      ".v-tabs-slider-wrapper"
    ) as HTMLDivElement;

    if (navItem && navSlider) {
      navSlider.style.width = navItem.getBoundingClientRect().width + "px";
    } else {
      setTimeout(this.setSliderWidth, 500);
    }
  }
}
