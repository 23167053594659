import { PanelRootState } from "@/panel/store/PanelRootState";

import { Action, ActionContext } from "vuex";
import { Ad, AdAction, AdState } from "../types";

const showAd = (
  context: ActionContext<AdState, PanelRootState>,
  payload: Ad
): void => {
  context.commit("setAd", payload);
};

const closeAd = (context: ActionContext<AdState, PanelRootState>): void => {
  context.commit("setAd", undefined);
};

export const actions: Record<AdAction, Action<AdState, PanelRootState>> = {
  // TODO: implement ad queue, only 1 ad is visible at the same time
  [AdAction.closeAd]: closeAd,
  [AdAction.Ad]: showAd,
  [AdAction.ImageAd]: showAd,
  [AdAction.VideoAd]: showAd,
  [AdAction.AnimatedAd]: showAd,
};
