

































import { Vue, Component, Emit } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { USER_NAMESPACE } from "@/shared/user/store";
import { UserInfo } from "@/shared/user/types";
import ProfileEditDialog from "./ProfileEditDialog.vue";
import { UI_EVENT_NAMESPACE } from "@/shared/ui-event/store";
import {
  UiEvent,
  UiEventACTION,
  UiEventCategory,
} from "@/shared/ui-event/types";

@Component({
  components: { ProfileEditDialog },
})
export default class ProfileDialog extends Vue {
  dialog = true;

  @Action("emit", { namespace: UI_EVENT_NAMESPACE })
  emitUiEvent!: (uiEvent: UiEvent) => Promise<void>;

  @Getter("userInfo", { namespace: USER_NAMESPACE })
  readonly userInfo!: UserInfo;

  get name() {
    return this.userInfo.displayName;
  }

  get imageUrl() {
    return this.userInfo.avatar.src;
  }

  @Emit()
  close(): boolean {
    this.dialog = false;
    return true;
  }

  openProfileEdit(): void {
    this.close();
    this.emitUiEvent({
      action: UiEventACTION.OPEN,
      category: UiEventCategory.PROFILE_EDIT,
    });
  }
}
