export interface UiEventState {
  uiEvent?: UiEvent;
}

export interface UiEvent {
  action: string;
  category: string;
}

export enum UiEventACTION {
  CLOSE = "CLOSE",
  OPEN = "OPEN",
}

export enum UiEventCategory {
  PROFILE = "PROFILE",
  PROFILE_EDIT = "PROFILE_EDIT",
}
