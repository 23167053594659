


























import { TRACKING_NAMESPACE } from "@/shared/tracking/store";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Action } from "vuex-class";
import { Ad, AdType, ImageAd, VideoAd } from "../types";

@Component({})
export default class AdItem extends Vue {
  @Action("logEvent", { namespace: TRACKING_NAMESPACE })
  logEvent!: Function;

  @Prop()
  ad!: Ad;

  @Watch("ad", { immediate: true })
  onAdChanged(ad: Ad): void {
    if (ad) {
      if ((ad as ImageAd).image) {
        this.adType = AdType.image;
      } else if ((ad as VideoAd).video) {
        this.adType = AdType.video;
      }

      if (ad.closableAfterSeconds) {
        this.showCloseButton = false;

        setTimeout(() => {
          this.showCloseButton = true;
        }, ad.closableAfterSeconds * 1000);
      }
    }
  }

  adType: AdType | undefined;
  showCloseButton = true;

  private timestamp = 0;

  created(): void {
    this.timestamp = Date.now();
    this.logEvent({
      ea: "show",
      ec: "ad",
      el: this.ad.id,
    });
  }

  close(event: Event | null = null): void {
    if (event) {
      event.stopImmediatePropagation();
      event.stopPropagation();
    }
    this.logEvent({
      ea: "close-click",
      ec: "ad",
      el: this.ad.id,
      ev: Date.now() - this.timestamp,
    });
    this.$emit("close");
  }

  trackClick(event: Event): void {
    event.stopImmediatePropagation();
    event.stopPropagation();
    this.logEvent({
      ea: "click",
      ec: "ad",
      ed: this.ad.clickLink,
      el: this.ad.id,
    });
  }

  videoEnded(): void {
    this.close();
  }
}
