import { PanelRootState } from "@/panel/store/PanelRootState";
import { GetterTree } from "vuex";
import { Ad, AdState } from "../types";

export const getters: GetterTree<AdState, PanelRootState> = {
  ad(state): Ad | undefined {
    return state.ad;
  },
  adVisible(state): boolean {
    return state.ad !== undefined;
  },
};
