








import { NAVIGATION_NAMESPACE } from "@/panel/navigation/store";
import { NavigationItem } from "@/panel/navigation/types";
import { CONFIG_NAMESPACE } from "@/shared/config/store";
import { Config } from "@/shared/config/types";
import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { APP_NAMESPACE } from "../store";

@Component({})
export default class AppStrap extends Vue {
  @Getter("config", { namespace: CONFIG_NAMESPACE })
  config!: Config;

  @Action("toggleVisibility", { namespace: APP_NAMESPACE })
  toggleVisibility!: (visible?: boolean) => void;

  @Getter("navbarItems", { namespace: NAVIGATION_NAMESPACE })
  navbarItems!: NavigationItem[];

  get hiddenStrap() {
    return this.navbarItems.length === 0;
  }
}
