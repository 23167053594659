































import { CONFIG_NAMESPACE } from "@/shared/config/store";
import { AppConfig, Config, Options } from "@/shared/config/types";
import { IFRAME_BRIDGE_NAMESPACE } from "@/shared/iFrameBridge/store";
import { IFrameErrorPayload, IFrameMessage } from "@/shared/iFrameBridge/types";
import AdArea from "./ads/components/AdArea.vue";
import AppBar from "./app/components/AppBar.vue";
import AppStrap from "./app/components/AppStrap.vue";
import Navigation from "./navigation/components/Navigation.vue";
import DialogRoot from "@/shared/components/DialogRoot.vue";
import Vue from "vue";
import Component from "vue-class-component";
import { Action, Getter } from "vuex-class";
import { Watch } from "vue-property-decorator";
import { navigate } from "./navigation/NavigationUtils";
import { USER_NAMESPACE } from "@/shared/user/store";
import { UserInfo } from "@/shared/user/types";
import { APP_NAMESPACE } from "./app/store";
import { TRACKING_NAMESPACE } from "@/shared/tracking/store";
import { HitType } from "@/shared/tracking/types";
import { UI_EVENT_NAMESPACE } from "@/shared/ui-event/store";
import {
  UiEvent,
  UiEventACTION,
  UiEventCategory,
} from "@/shared/ui-event/types";
import { AUTH_NAMESPACE } from "@/shared/auth/store";
import { NAVIGATION_NAMESPACE } from "./navigation/store";
import { NavigationItem } from "./navigation/types";
import { AD_NAMESPACE } from "@/panel/ads/types";
import { ApplicationType } from "@/shared/@types/app";
import { THEME_NAMESPACE } from "@/shared/theme/store";
import { ThemeConfig } from "@/shared/theme/types";
import { apiService } from "@/shared/services/api-service";
import { isGema } from "@/shared/utils";

@Component({
  components: {
    AdArea,
    AppBar,
    AppStrap,
    DialogRoot,
    Navigation,
  },
})
export default class Panel extends Vue {
  showStrap = false;
  showContent = true;
  loading = true;

  @Action("handleMessage", { namespace: IFRAME_BRIDGE_NAMESPACE })
  handleMessage!: (ev: MessageEvent) => Promise<void | IFrameErrorPayload>;
  @Action("postMessage", { namespace: IFRAME_BRIDGE_NAMESPACE })
  postMessage!: (message: IFrameMessage) => void;

  @Action("logEvent", { namespace: TRACKING_NAMESPACE })
  logEvent!: Function;

  @Action("emit", { namespace: UI_EVENT_NAMESPACE })
  emitUiEvent!: (uiEvent: UiEvent) => Promise<void>;

  @Watch("$route")
  onRouteChanged(): void {
    this.logEvent({ ht: HitType.PAGEVIEW });
  }
  @Getter("navbarItems", { namespace: NAVIGATION_NAMESPACE })
  navbarItems!: NavigationItem[];

  @Getter("theme", { namespace: THEME_NAMESPACE })
  theme!: ThemeConfig;
  @Watch("theme")
  onThemeChanged(theme: ThemeConfig): void {
    this.$vuetify.theme.themes.light = {
      ...this.$vuetify.theme.themes.light,
      ...theme.themes.light,
    };
    this.$vuetify.theme.themes.dark = {
      ...this.$vuetify.theme.themes.dark,
      ...theme.themes.dark,
    };
    this.$vuetify.theme.dark = theme.dark;
    if (theme.userColors) {
      this.$spect8ChatUi.setConfig({ userColors: theme.userColors });
    }
    if (theme.roleBadges) {
      this.$spect8ChatUi.setConfig({ roleBadges: theme.roleBadges });
    }

    // Update overlay theme
    this.postMessage({
      type: "appEvent",
      payload: {
        category: "theme",
        data: theme,
      },
      destination: ApplicationType.OVERLAY,
    });
  }

  @Getter("config", { namespace: CONFIG_NAMESPACE })
  config!: Config;

  @Watch("config")
  onConfigChanged() {
    this.postMessage({
      type: "appEvent",
      payload: {
        category: "config",
        data: this.config,
      },
      destination: ApplicationType.OVERLAY,
    });
  }

  @Getter("options", { namespace: CONFIG_NAMESPACE })
  options!: Options;

  @Watch("options")
  onOptionsChanged() {
    if (this.options) {
      this.postMessage({
        type: "appEvent",
        payload: {
          category: "language",
          data: {
            defaultLanguage: this.options.defaultLanguage,
          },
        },
        destination: ApplicationType.OVERLAY,
      });
    }
  }

  @Getter("ad", { namespace: AD_NAMESPACE })
  ad!: null;

  get appBarVisible(): boolean {
    return (
      !this.options.hiddenAppbar &&
      (this.options.collapsible || this.options.profile)
    );
  }
  get collapsible(): boolean {
    return this.options.collapsible ?? false;
  }

  @Getter("initialized", { namespace: CONFIG_NAMESPACE })
  initialized!: boolean;

  @Watch("initialized")
  onInitializedChanged(initialized: boolean): void {
    if (initialized) {
      this.postMessage({
        type: "appEvent",
        payload: {
          category: "app",
          action: "initialized",
        },
      });

      if (this.navbarItems.length > 0) {
        navigate(this.navbarItems[0].name);
      }

      this.loading = false;

      if (this.config.features.enabled) {
        this.$spect8ChatUi.setConfig({
          enabledFeatures: this.config.features.enabled,
        });
      }

      if (this.config.mediaUploadBaseUrl) {
        this.$spect8ChatUi.setConfig({
          mediaUploadBaseUrl: this.config.mediaUploadBaseUrl,
        });
      }

      if (this.options.chatConfig.style) {
        this.$spect8ChatUi.setConfig({
          style: this.options.chatConfig.style,
        });
      }

      if (this.appConfig) this.startKeepAlive();
    }
  }

  @Getter("authenticated", { namespace: AUTH_NAMESPACE })
  authenticated!: boolean;
  @Watch("authenticated")
  onAuthenticatedChanged(authenticated: boolean) {
    if (this.appConfig) {
      this.$spect8ChatUi.setAuth({
        authenticated: authenticated,
        userInfoIsValid: this.userInfo?.displayName !== null,
        user: this.userInfo,
      });
    }
  }

  @Getter("appConfig", { namespace: CONFIG_NAMESPACE })
  appConfig!: AppConfig;
  @Watch("appConfig")
  onAppConfigChanged(appConfig: AppConfig, oldAppConfig: AppConfig): void {
    if (appConfig !== oldAppConfig) {
      this.$spect8ChatUi.setConfig({
        appConfig: {
          broadcastId: appConfig.broadcastId,
          tenantId: appConfig.tenantId,
          nchanUrl: process.env.VUE_APP_NCHAN_URL,
        },
      });
      this.$spect8ChatUi.setAuth({
        authenticated: this.authenticated,
        userInfoIsValid: this.userInfo?.displayName !== null,
        user: this.userInfo,
      });
    }
  }

  @Getter("userInfo", { namespace: USER_NAMESPACE })
  userInfo!: UserInfo;
  @Watch("userInfo")
  onUserInfoChanged(userInfo: UserInfo) {
    if (this.appConfig) {
      this.$spect8ChatUi.setAuth({
        authenticated: this.authenticated,
        userInfoIsValid: userInfo?.displayName !== null,
        user: userInfo,
      });
    }
  }

  @Getter("visible", { namespace: APP_NAMESPACE })
  isVisible!: boolean;
  @Watch("isVisible")
  onVisibilityChanged(visible: boolean): void {
    if (!visible) {
      this.showContent = false;
      this.showStrap = true;
    } else {
      this.showStrap = false;
      this.showContent = true;
    }
  }

  @Getter("adVisible", { namespace: AD_NAMESPACE })
  adVisible!: boolean;

  @Getter(`${CONFIG_NAMESPACE}/tenantName`) tenantName!: string;
  get tabVisible() {
    return isGema(this.tenantName) || this.navbarItems.length > 1;
  }

  created(): void {
    if (process.env.VUE_APP_COMMIT_HASH)
      console.info("commit:", process.env.VUE_APP_COMMIT_HASH);

    window.addEventListener("message", this.onHandleMessage, false);
  }

  afterLeaveStrap(): void {
    this.showContent = true;
  }

  afterLeaveContent(): void {
    this.showStrap = true;
  }

  handleControlMessage(action: string) {
    switch (action) {
      case "openProfileEdit":
        this.emitUiEvent({
          action: UiEventACTION.OPEN,
          category: UiEventCategory.PROFILE_EDIT,
        });
        break;

      default:
        break;
    }
  }

  private onHandleMessage(messageEvent: MessageEvent): void {
    this.handleMessage(messageEvent).catch((err) => {
      this.$log.error(err);
      this.postMessage({
        type: "error",
        payload: err,
      });
    });
  }

  keepAliveInterval?: ReturnType<typeof setInterval>;
  startKeepAlive() {
    if (this.appConfig) {
      if (this.keepAliveInterval) clearInterval(this.keepAliveInterval);

      apiService.pingPresence(this.appConfig.broadcastId);
      this.keepAliveInterval = setInterval(() => {
        apiService.pingPresence(this.appConfig.broadcastId);
      }, 24000);
    }
  }
}
