







































































































import { Vue, Component, Emit } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import ProfileImagePicker from "./ProfileImagePicker.vue";
import ProfileMediaPicker from "./ProfileMediaPicker.vue";
import { USER_NAMESPACE } from "@/shared/user/store";
import { UserInfo, UserUpdatePayload } from "@/shared/user/types";

@Component({
  components: { ProfileImagePicker, ProfileMediaPicker },
})
export default class ProfileEditDialog extends Vue {
  @Getter("created", { namespace: USER_NAMESPACE })
  readonly userCreated!: boolean;
  @Getter("userInfo", { namespace: USER_NAMESPACE })
  readonly userInfo!: UserInfo;
  @Getter("hasUpdateError", { namespace: USER_NAMESPACE })
  readonly hasUpdateError!: boolean;

  @Action("update", { namespace: USER_NAMESPACE })
  updateProfileAction!: (updatePayload: UserUpdatePayload) => Promise<void>;

  dialog = true;
  isMediaPickerOpen = false;

  avatarFile: File | null = null;
  username = "";
  selectedAvatar = "";
  selectedBadge = "";

  updateInProgress = false;
  errorMessage = "";

  color = "#FFFFFF";
  swatches = [
    ["#FFFFFF"],
    ["#FF5DD4"],
    ["#B675FF"],
    ["#6D9DFC"],
    ["#00FFF6"],
    ["#00FF90"],
    ["#09E101"],
    ["#D9F513"],
    ["#FF8A23"],
  ];
  menu = false;

  uploadDisabled = true;

  get title() {
    if (this.isMediaPickerOpen) {
      return this.$t("profileDialog.customiseProfile");
    } else if (!this.userCreated) {
      return this.$t("profileDialog.startChatting");
    }
    return this.$t("profileDialog.editTitle");
  }

  get disabled() {
    const format = /[!@#$%^&*()_+\-=/€÷×ß¤Łł§ [\]{};':"\\|,.<>?]/;
    if (
      this.username.length > 32 ||
      this.username.length < 3 ||
      this.username.match(format)
    ) {
      return true;
    } else {
      return false;
    }
  }

  get hasError() {
    const format = /[!@#$%^&*()_+\-=/€÷×ß¤Łł§ [\]{};':"\\|,.<>?]/;
    if (this.username.match(format)) {
      this.errorMessage = "Please only use letters (a-z) and numbers.";
      return true;
    } else if (this.hasUpdateError) {
      this.errorMessage = "Username already taken.";
      return true;
    } else {
      this.errorMessage = "";
      return false;
    }
  }

  get colorClass() {
    if (this.color === "#FFFFFF") return "input-white";
    if (this.color === "#FF5DD4") return "input-pink";
    if (this.color === "#B675FF") return "input-purple";
    if (this.color === "#6D9DFC") return "input-blue";
    if (this.color === "#00FFF6") return "input-cyan";
    if (this.color === "#00FF90") return "input-light-green";
    if (this.color === "#09E101") return "input-green";
    if (this.color === "#D9F513") return "input-yellow";
    if (this.color === "#FF8A23") return "input-orange";
    else return "input-white";
  }

  get avatarUrl() {
    if (this.selectedAvatar !== "") {
      return this.selectedAvatar;
    } else if (this.avatarFile !== null) {
      return URL.createObjectURL(this.avatarFile);
    } else if (this.userInfo) {
      return this.userInfo.avatar.src;
    }
    return "";
  }

  get badgeUrl() {
    if (this.selectedBadge !== "") {
      return this.selectedBadge;
    } else if (this.userInfo.badge?.src) {
      return this.userInfo.badge.src;
    }
    return "";
  }

  created(): void {
    if (this.userInfo.displayName) {
      this.username = this.userInfo.displayName;
    }
    if (this.userInfo.color) {
      this.color = this.userInfo.color;
    }
  }

  @Emit()
  close(): boolean {
    this.dialog = false;
    return true;
  }

  onSelectedFile(file: File): void {
    this.avatarFile = file;
  }

  closeMediaPicker(): void {
    this.isMediaPickerOpen = false;
  }

  toggleMediaPicker(): void {
    this.isMediaPickerOpen = !this.isMediaPickerOpen;
  }

  onSelectedMedia(avatar: string, badge: string): void {
    this.closeMediaPicker();
    if (badge === "" && this.userInfo.badge) this.userInfo.badge.src = "";
    this.selectedAvatar = avatar;
    this.selectedBadge = badge;
  }

  updateProfile(): void {
    const updatePayload: UserUpdatePayload = {
      displayName: this.username,
      color: this.color,
    };

    if (this.uploadDisabled && this.selectedAvatar !== "") {
      updatePayload.avatarUrl = this.selectedAvatar;
    } else if (this.avatarFile) {
      updatePayload.avatarFile = this.avatarFile;
    } else if (this.userInfo?.avatar.src) {
      updatePayload.avatarUrl = this.userInfo?.avatar.src;
    } else if (this.selectedAvatar === "" && !this.userInfo.avatar.src) {
      updatePayload.avatarUrl =
        "https://cdn.spect8.io/chat/avatars/baseline_account_circle_white_48dp_2x.png";
    }

    if (this.selectedBadge !== "") {
      updatePayload.badgeUrl = this.selectedBadge;
    } else if (this.selectedBadge === "" && !this.userInfo.badge?.src) {
      updatePayload.badgeUrl = "";
    } else if (this.selectedBadge === "" && this.userInfo.badge?.src) {
      updatePayload.badgeUrl = this.userInfo.badge.src;
    }

    this.updateInProgress = true;
    this.updateProfileAction(updatePayload).then(() => {
      this.updateInProgress = false;
      if (!this.hasUpdateError) {
        this.close();
      }
    });
  }
}
