import { AppRootState } from "@/shared/@types/app";
import { ActionTree } from "vuex";
import { UiEvent, UiEventState } from "../types";

export const actions: ActionTree<UiEventState, AppRootState> = {
  emit(context, uiEvent: UiEvent): Promise<void> {
    return new Promise<void>((resolve) => {
      context.commit("uiEvent", uiEvent);
      context.dispatch(
        "tracking/logEvent",
        {
          ea: uiEvent.action,
          ec: uiEvent.category,
        },
        { root: true }
      );
      resolve();
    });
  },
};
