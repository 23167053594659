















































import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { USER_NAMESPACE } from "@/shared/user/store";
import { UserInfo } from "@/shared/user/types";
import ProfileDialog from "./ProfileDialog.vue";
import ProfileEditDialog from "./ProfileEditDialog.vue";
import { AUTH_NAMESPACE } from "@/shared/auth/store";
import { UI_EVENT_NAMESPACE } from "@/shared/ui-event/store";
import {
  UiEvent,
  UiEventACTION,
  UiEventCategory,
} from "@/shared/ui-event/types";

@Component({
  components: { ProfileDialog, ProfileEditDialog },
})
export default class AppBarProfile extends Vue {
  @Getter("authenticated", { namespace: AUTH_NAMESPACE })
  readonly authenticated!: boolean;

  @Getter("created", { namespace: USER_NAMESPACE })
  readonly userCreated!: boolean;
  @Getter("userInfo", { namespace: USER_NAMESPACE })
  readonly userInfo!: UserInfo;

  @Action("emit", { namespace: UI_EVENT_NAMESPACE })
  emitUiEvent!: (uiEvent: UiEvent) => Promise<void>;

  showDialog(category: UiEventCategory): void {
    this.emitUiEvent({
      action: UiEventACTION.OPEN,
      category: category,
    });
  }

  get nameColor() {
    const c = this.userInfo.color;
    if (!c || c === "#FFFFFF")
      return this.$vuetify.theme.dark ? "#fdfdfd" : "#0a0a0a";
    else return c;
  }
}
