import Vue from "vue";
import { MutationTree } from "vuex";
import { ImageMedia, MediaState } from "../types";

export const mutations: MutationTree<MediaState> = {
  avatars(state, avatars: ImageMedia[]) {
    Vue.set(state, "avatars", avatars);
  },
  badges(state, badges: ImageMedia[]) {
    Vue.set(state, "badges", badges);
  },
};
