import { AppRootState } from "@/shared/@types/app";
import { GetterTree } from "vuex";
import { ImageMedia, MediaState } from "../types";

export const getters: GetterTree<MediaState, AppRootState> = {
  avatars(state): ImageMedia[] {
    return state.avatars ?? [];
  },
  badges(state): ImageMedia[] {
    return state.badges ?? [];
  },
};
