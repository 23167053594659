import { initPollsService } from "@/panel/polls/services/polls-service";
import { i18n } from "@/plugins/i18n/vue-i18n-plugin";
import vuetify from "@/plugins/vuetify-plugin";
import { initAuthService } from "@/shared/auth/services/auth-service";
import { initApiService } from "@/shared/services/api-service";
import { initIAService } from "./qa/services/ia-service";
import Spect8ChatUiPlugin from "@vaudience/spect8-chat-ui-vue";
import "@vaudience/spect8-chat-ui-vue/dist/spect8-chat-ui.css";
import Vue from "vue";
import PerfectScrollbar from "vue2-perfect-scrollbar";
import "vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css";
import { LogLevels } from "vuejs-logger/dist/enum/log-levels";
import VueLoggerPlugin from "vuejs-logger/dist/vue-logger";
import Panel from "./Panel.vue";
import router from "./router";
import store from "./store";
import { initTrackingService } from "@/shared/tracking/tracking-service";
import { initGemaApi } from "./gema/api";

Vue.config.productionTip = false;

const isProduction = process.env.NODE_ENV === "production";

const logOptions = {
  isEnabled: true,
  logLevel: isProduction ? LogLevels.ERROR : LogLevels.DEBUG,
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: true,
  separator: "|",
  showConsoleColors: true,
};
Vue.use(VueLoggerPlugin, logOptions);

Vue.use(PerfectScrollbar, { options: { wheelPropagation: false } });

initAuthService();
const apiService = initApiService(store);
initPollsService(store);
initIAService(store);
initTrackingService(router);
initGemaApi(store);

// initTrackingModule({}, router, store);
// initUserModule(store);
// initCoreApiService(API_BASE_URL, store);
// initConfigModule(
//   {
//     mediaBaseUrl: process.env.VUE_APP_MEDIA_BASE_URL || ""
//   },
//   store
// );
// initAuthModule(
//   {
//     loginScopeKey: "s8-shell-login-scope",
//     // notificationCallback: showNotification,
//     routes: {
//       home: "",
//       login: ""
//     },
//     fusionAuthUrl: process.env.VUE_APP_FUSION_AUTH_URL || "",
//     pinAuthUrl: process.env.VUE_APP_PIN_URL || "",
//     sessionAuthUrl: process.env.VUE_APP_SESSION_AUTH_URL || ""
//   },
//   router,
//   store
// );

Vue.use(Spect8ChatUiPlugin, { apiService, store, config: undefined, i18n });

new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: (h) => h(Panel),
}).$mount("#app");
