import { AppRootState } from "@/shared/@types/app";
import axios from "axios";
import Vue from "vue";
import { ActionTree } from "vuex";
import { MediaState } from "../types";

export const actions: ActionTree<MediaState, AppRootState> = {
  fetchAvatars(context): Promise<void> {
    return new Promise<void>((resolve) => {
      if (context.state.avatars) {
        return resolve();
      }
      axios.get("./json/avatars.json").then((response) => {
        const avatars = response.data;
        Vue.$log.debug("avatars", avatars);
        context.commit("avatars", avatars);
        resolve();
      });
    });
  },
  fetchBadges(context, dark: boolean): Promise<void> {
    return new Promise<void>((resolve) => {
      if (context.state.badges) {
        return resolve();
      }
      // TODO: get badges from backend
      // TODO: find a better way to access the theme
      const url = `./json/badges-${dark ? "dark" : "light"}.json`;
      axios.get(url).then((response) => {
        const badges = response.data;
        Vue.$log.debug("badges", badges);
        context.commit("badges", badges);
        resolve();
      });
    });
  },
};
