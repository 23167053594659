import { ad } from "@/panel/ads/store";
import { app } from "@/panel/app/store";
import { merch } from "@/panel/merch/store";
import { navigation } from "@/panel/navigation/store";
import { poll } from "@/panel/polls/store";
import { resources, RESOURCE_NAMESPACE } from "@/panel/resources/store";
import { ApplicationType } from "@/shared/@types/app";
import { auth } from "@/shared/auth/store";
import { config } from "@/shared/config/store";
import { iFrameBridge } from "@/shared/iFrameBridge/store";
import { media } from "@/shared/media/store";
import { nchan } from "@/shared/nchan/store";
import { theme } from "@/shared/theme/store";
import { tracking } from "@/shared/tracking/store";
import { uiEvent } from "@/shared/ui-event/store";
import { user } from "@/shared/user/store";
import Vue from "vue";
import Vuex, { StoreOptions } from "vuex";
import { PanelRootState } from "./PanelRootState";
import { qa, QA_NAMESPACE } from "@/panel/qa/store";
import { sv, SV_NAMESPACE } from "../secure-voting/store";
import { GEMA_NAMESPACE, gema } from "../gema/store";

Vue.use(Vuex);

const store: StoreOptions<PanelRootState> = {
  state: {
    version: "0.1.0",
    applicationType: ApplicationType.PANEL,
  },
  modules: {
    auth,
    config,
    iFrameBridge,
    media,
    nchan,
    theme,
    tracking,
    uiEvent,
    user,

    ad,
    app,
    navigation,
    merch,
    poll,
    [QA_NAMESPACE]: qa,
    [RESOURCE_NAMESPACE]: resources,
    [SV_NAMESPACE]: sv,
    [GEMA_NAMESPACE]: gema,
  },
};

export default new Vuex.Store<PanelRootState>(store);
