














































import { TRACKING_NAMESPACE } from "@/shared/tracking/store";
import { Vue, Component, Prop } from "vue-property-decorator";
import { Action } from "vuex-class";
import ProfileAvatars from "./ProfileAvatars.vue";
import ProfileBadges from "./ProfileBadges.vue";

@Component({
  components: { ProfileAvatars, ProfileBadges },
})
export default class ProfileMediaPicker extends Vue {
  currentTab = null;
  selectedAvatar = "";
  selectedBadge = "";

  @Action("logEvent", { namespace: TRACKING_NAMESPACE })
  logEvent!: Function;

  @Prop() readonly avatar!: string;
  @Prop() readonly badge!: string;

  created(): void {
    this.selectedAvatar = this.avatar;
    this.selectedBadge = this.badge;
  }

  confirm(): void {
    this.$emit("selectedMedia", this.selectedAvatar, this.selectedBadge);
    this.logEvent({
      ea: "confirm",
      ec: "profile-media-picker",
    });
  }

  onSelectedAvatar(avatar: string): void {
    this.selectedAvatar = avatar;
  }

  onSelectedBadge(badge: string): void {
    this.selectedBadge = badge;
  }
}
