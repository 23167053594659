











































import { Vue, Component, Prop } from "vue-property-decorator";
@Component({})
export default class ProfileImagePicker extends Vue {
  selectedFile: File | null = null;
  hover = false;

  @Prop({ default: true }) readonly uploadDisabled!: boolean;
  @Prop() readonly url!: string;

  get imageUrl() {
    if (this.selectedFile !== null) {
      return URL.createObjectURL(this.selectedFile);
    }
    return this.url;
  }

  onClick() {
    if (!this.uploadDisabled) {
      (this.$refs.fileInput as HTMLInputElement).click();
    } else {
      this.$emit("click");
    }
  }

  onFileSelected(event: InputEvent) {
    const target = event.target as HTMLInputElement;
    this.selectedFile =
      target?.files !== null && target?.files.length > 0
        ? target?.files[0]
        : null;
    if (this.selectedFile) {
      this.$emit("selectedfile", this.selectedFile);
    }
  }
}
