






import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import AdItem from "@/panel/ads/components/AdItem.vue";
import { Ad, AD_NAMESPACE } from "@/panel/ads/types";

@Component({
  components: {
    AdItem,
  },
})
export default class AdArea extends Vue {
  @Action("closeAd", { namespace: AD_NAMESPACE })
  closeAd!: () => void;

  @Getter("ad", { namespace: AD_NAMESPACE })
  ad!: Ad | undefined;

  handleClose(): void {
    this.closeAd();
  }
}
