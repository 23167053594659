












import { Component, Prop, Vue } from "vue-property-decorator";
import AppBarProfile from "@/panel/profile/AppBarProfile.vue";
import { Action, Getter } from "vuex-class";
import { APP_NAMESPACE } from "../store";
import { CONFIG_NAMESPACE } from "@/shared/config/store";
import { Options } from "@/shared/config/types";
import { IFrameMessage } from "@/shared/iFrameBridge/types";
import { IFRAME_BRIDGE_NAMESPACE } from "@/shared/iFrameBridge/store";

@Component({
  components: {
    AppBarProfile,
  },
})
export default class AppBar extends Vue {
  @Prop()
  tabVisible!: boolean;

  @Action("toggleVisibility", { namespace: APP_NAMESPACE })
  toggleVisibility!: (visible?: boolean) => void;
  @Action(`${IFRAME_BRIDGE_NAMESPACE}/postMessage`)
  postMsg!: (msg: IFrameMessage) => void;

  @Getter("options", { namespace: CONFIG_NAMESPACE })
  options!: Options;

  get profile(): boolean {
    return this.options.profile ?? false;
  }
  get collapsible(): boolean {
    return this.options.collapsible ?? false;
  }
}
